.container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.container img {
  transition: transform 5s ease-in;
  transform-origin: center;
}

.container.animate img {
  animation: scaleUpAndDown 5s ease-in forwards;
}

@keyframes scaleUpAndDown {
  0%,
  100% {
    opacity: 0;
    transform: scale(1);
  }
  30% {
    opacity: 1;
    transform: scale(2);
  }
}
