/* reset */
body {
  margin: 0;
}

@font-face {
  font-family: "GTWalsheim";
  font-weight: bold;
  src: url(../public/fonts/GTWalsheim-Bd.ttf) format("truetype");
}

@font-face {
  font-family: "GTWalsheim";
  font-weight: normal;
  src: url(../public/fonts/GTWalsheim-Rg.ttf) format("truetype");
}

/* @font-face {
    font-family: 'GTWalsheim';
    font-weight: lighter;
    src: url(../public/fonts/GTWalsheim-ULt.ttf) format('truetype');
  } */
/*   
  @font-face {
    font-family: 'GTWalsheim-CnRg';
    src: url(../public/fonts/GTWalsheim-CnRg.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'GTWalsheim-CnBd';
    src: url(../public/fonts/GTWalsheim-CnBd.ttf) format('truetype');
  } */

/* from https://albyianna.medium.com/creating-an-auto-scrollable-element-with-css-b7d814c73522 */
.autoscrollable-wrapper {
  overflow: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.highlighted {
  background-color: darkcyan;
}

/* https://codepen.io/jweden/pen/kGBBpM */
/* .post-it {
    width:250px;
   height:240px;
   position:relative;
   background:#ffa;
   overflow:hidden;
   margin:30px auto;
   padding:20px;
   border-radius:0 0 0 30px/45px;
   box-shadow:
     inset 0 -40px 40px rgba(0,0,0,0.2),
     inset 0 25px 10px rgba(0,0,0,0.2),
     0 5px 6px 5px rgba(0,0,0,0.2);
   font-family: 'Permanent Marker', cursive;
   line-height:1.7em;
   font-size:19px;
   -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
 
   color:#130d6b;
 }
 
 
 .post-it:before {
    content:"";
   display:block;
   position:absolute;
   width:20px;
   height:25px;
   background:#ffa;
   box-shadow:
     3px -2px 10px rgba(0,0,0,0.2),
     inset 15px -15px 15px rgba(0,0,0,0.3);
   left:0;
   bottom:0;
   z-index:2;
   transform:skewX(25deg);
 }
 
 .post-it:after {
    content:"";
   display:block;
   position:absolute;
   width:75%;
   height:20px; 
   border-top:3px solid #130d6b;
   border-radius: 50% ;
   bottom:0px;
   left:10%;
 }
  */

/* https://dev.to/thormeier/fully-responsive-html-css-sticky-note-4okl */
/* Some positioning and ratios */
.sticky-container {
  max-width: 500px;
  position: relative;
}

.sticky-outer {
  display: flex;
  padding-top: 35.5925926%;
  position: relative;

  width: 100%;
}

.sticky {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Shadow behind the sticky note */
.sticky:before {
  box-shadow: -2px 2px 15px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.25);
  content: "";
  width: 90%;
  left: 5px;
  height: 83%;
  position: absolute;
  top: 30%;
}

/* The sticky note itself */
.sticky-content {
  background-color: antiquewhite;
  /* background: linear-gradient(
    180deg,
    rgba(187, 235, 255, 1) 0%,
    rgba(187, 235, 255, 1) 12%,
    rgba(170, 220, 241, 1) 75%,
    rgba(195, 229, 244, 1) 100%
  ); */
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: 'Kalam', cursive; */
  font-size: 1rem;

  clip-path: url(#stickyClip);
}

/* Add responsiveness */
@media screen and (min-width: 640px) {
  .sticky:before {
    height: 79%;
    width: 90%;
  }
  .sticky-content {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 768px) {
  .sticky:before {
    height: 75%;
    width: 90%;
  }
  .sticky-content {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .sticky:before {
    height: 73%;
    width: 90%;
  }
  .sticky-content {
    font-size: 1.875rem;
  }
}

.scrollToBottomButton {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='%23626262' d='m12 18l-6-6l1.4-1.4l3.6 3.6V5h2v9.2l3.6-3.6L18 12z'/%3E%3C/svg%3E")
    no-repeat center;
  background-size: contain;
}

.PdfHighlighter {
  scroll-behavior: smooth;
}
.ant-popconfirm-inner-content {
  padding: 10px;
}

.ant-popconfirm-message {
  justify-content: center;
}

.ant-popconfirm-buttons {
  display: flex;
  justify-content: end;
  gap: 12px;
  margin-top: 24px;
}

.ant-popconfirm-buttons button {
  /* default middle */
  height: 32px;
  margin-left: 0px !important;
  /* for OK buttons for instance */
  min-width: 70px;
}
